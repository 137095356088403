import { compare, getRandomNumerInRange, isNumber, toNumber } from './Utilities';

// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sortByProperties', {
	// Example function call
	// arrayVar.sortByProperties([{ prop: 'objectPropertyName_1' }, { prop: 'objectPropertyName_2.subPropertyName_1', dir: -1 }])
	value: function (sorts) {
		// sorts is an array of sorting option objects is the following format: sort = { prop: 'objectPropertyName', dir: 1 }
		// sort.prop is a string of a property name in JS object property notation e.g. 'property.subPropertry'
		// sort.dir is a numerical value that the sort compare is multiplid by, i.e. 1 is ascending and -1 is descending
		return this.sort((a, b) =>
			compare(
				sorts.map((sort) => compare(a[sort.prop ?? ''], b[sort.prop ?? '']) * (sort.dir ?? 1)),
				sorts.map((sort) => compare(b[sort.prop ?? ''], a[sort.prop ?? '']) * (sort.dir ?? 1))
			)
		);
	},
	enumerable: false,
});

// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'getRandomItem', {
	value: function () {
		return this[Math.round(getRandomNumerInRange(0, this.length - 1))];
	},
	enumerable: false,
});

// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sumValues', {
	value: function () {
		return this.reduce((a, b) => toNumber(a, 0) + toNumber(b, 0), 0);
	},
	enumerable: false,
});

const nonRecurssiveReplacer = (key, value, clonedNodes) => {
	if (typeof value === 'object' && value !== null) {
		if (clonedNodes.includes(value)) return;
		else clonedNodes.push(value);
	}
	return value;
};

// eslint-disable-next-line
Object.defineProperty(Object.prototype, 'toCleanObject', {
	// Example function call
	// objectVar.toCleanObject()
	value: function () {
		if (this === null || !(this instanceof Object)) {
			return this;
		}

		var clonedNodes = [];

		let cleanObject = JSON.parse(JSON.stringify(this, (key, value) => nonRecurssiveReplacer(key, value, clonedNodes)));

		clonedNodes = null;

		return cleanObject;
	},
	enumerable: false,
});

// eslint-disable-next-line
Object.defineProperty(Object.prototype, 'toPrunedObject', {
	// Example function call
	// objectVar.toPrunedObject(3)
	value: function (depth) {
		depth = !isNumber(depth) ? 1 : depth;
		function prune(key, val, depth, currentNode, isArray) {
			if (!val || typeof val != 'object') {
				return val;
			} else {
				isArray = Array.isArray(val);

				JSON.stringify(val, function (replacerKey, replacerVal) {
					if (isArray || depth > 0) {
						if (!replacerKey) {
							isArray = Array.isArray(replacerVal);
							return (val = replacerVal);
						}

						!currentNode && (currentNode = isArray ? [] : {});
						currentNode[replacerKey] = prune(replacerKey, replacerVal, isArray ? depth : depth - 1);
					}
				});

				return currentNode || (isArray ? ['...'] : ({}[`${key}`] = '{...}'));
			}
		}
		return JSON.parse(JSON.stringify(prune('', this, depth)));
	},
	enumerable: false,
});

export const addClickOutListener = ({ element, wrapper, clickOutCallback, clickInCallback }) => {
	var bubbledFromElement = false;

	wrapper = wrapper ?? element.parentElement ?? document.body ?? document;

	wrapper.addEventListener(
		'click',
		(e) => {
			if (!bubbledFromElement) {
				clickOutCallback && typeof clickOutCallback === 'function' && clickOutCallback(e);
			}
			bubbledFromElement = false;
		},
		false
	);

	element.addEventListener(
		'click',
		(e) => {
			bubbledFromElement = true;
			clickInCallback && typeof clickInCallback === 'function' && clickInCallback(e);
			//e.stopPropagation(); // This is what prevents the wrapper event listener from firing
		},
		false
	);
};

// Example function call
// element.addClickOutListener({clickOutCallback: () => { console.log('click out'); },});
Object.defineProperty(Element.prototype, 'addClickOutListener', {
	value: function ({ wrapper, clickOutCallback, clickInCallback }) {
		addClickOutListener({ element: this, wrapper, clickOutCallback, clickInCallback });
	},
	enumerable: false,
});

const addLongPressListener_controllers = {};
export const addLongPressListener = ({ element, callback, delay = 500, preventClick = true }) => {
	if ((element.dataset && element.dataset.longPressHandled === 'true') || element.getAttribute('data-longPressListener') === 'true') {
		let existingController = addLongPressListener_controllers[element.id];
		existingController && existingController.abort();
	} else {
		element && element.dataset ? (element.dataset.longPressHandled = true) : element.setAttribute('data-longPressListener', 'true');
	}

	let controller = new AbortController();

	var longPressTimeout;
	var longPressed = false;

	var longPressStart = (e) => {
		longPressTimeout = setTimeout(() => {
			longPressed = true;
			e.stopPropagation();
			callback && typeof callback === 'function' && callback(e);
		}, delay);
	};

	var longClickCancel = (e) => {
		longPressTimeout && clearTimeout(longPressTimeout);
	};

	var longTouchCancel = (e) => {
		longPressTimeout && clearTimeout(longPressTimeout);
		longPressed = false;
	};

	var shortClick = (e) => {
		if (preventClick && longPressed) e.preventDefault();
		longPressed = false;
	};

	var contextMenu = (e) => e.preventDefault();

	element.addEventListener('touchstart', longPressStart, { useCapture: false, signal: controller.signal });
	element.addEventListener('mousedown', longPressStart, { useCapture: false, signal: controller.signal });

	element.addEventListener('contextmenu', contextMenu, { useCapture: false, signal: controller.signal });
	element.addEventListener('click', shortClick, { useCapture: false, signal: controller.signal });

	element.addEventListener('touchend', longTouchCancel, { useCapture: false, signal: controller.signal });
	element.addEventListener('touchmove', longTouchCancel, { useCapture: false, signal: controller.signal });

	element.addEventListener('mouseup', longClickCancel, { useCapture: false, signal: controller.signal });
	element.addEventListener('mousemove', longClickCancel, { useCapture: false, signal: controller.signal });

	addLongPressListener_controllers[element.id] = controller;
};

Object.defineProperty(Element.prototype, 'addLongPressListener', {
	value: function (callback, params) {
		addLongPressListener({ element: this, callback, ...params });
	},
	enumerable: false,
});

function debugHistory() {
	let self = this;
	self.log = [];
	self.warn = [];
	self.error = [];
	self.debug = [];
	self.info = [];

	self.data = () => {
		return {
			log: self.log,
			warn: self.warn,
			error: self.error,
			debug: self.debug,
			info: self.info,
		};
	};

	Object.defineProperty(self, 'observe', {
		value: function (callback) {
			if (typeof callback === 'function') self.observerCallback = callback;
		},
		enumerable: false,
	});

	let observerCallback = null;
	Object.defineProperty(self, 'observerCallback', {
		get: function () {
			return observerCallback;
		},
		set: function (value) {
			observerCallback = value;
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'pushToHistory', {
		value: function (category, data) {
			self[category] && self[category].push(data.toCleanObject().toPrunedObject(5));
			self[category] && self[category].length > 100 && self[category].sortByProperties([{ prop: 'Timestamp', dir: -1 }]).pop();
			self.saveToStorage();
			self.observerCallback && self.observerCallback.call(this, self);
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'saveToStorage', {
		value: function () {
			localStorage.setItem('debug_history', JSON.stringify(self.data()));
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'loadFromStorage', {
		value: function () {
			let savedHistory = JSON.parse(localStorage.getItem('debug_history'));

			self.log = savedHistory?.log ?? [];
			self.warn = savedHistory?.warn ?? [];
			self.error = savedHistory?.error ?? [];
			self.debug = savedHistory?.debug ?? [];
			self.info = savedHistory?.info ?? [];

			self.observerCallback && self.observerCallback.call(this, self);
		},
		enumerable: false,
	});

	Object.defineProperty(self, 'clearFromStorage', {
		value: function () {
			localStorage.setItem('debug_history', null);
			self.loadFromStorage();
		},
		enumerable: false,
	});

	self.loadFromStorage();
}

export const debug_history = new debugHistory();

const log = console.log.bind(console);
console.log = (...args) => {
	debug_history.pushToHistory('log', { Timestamp: Date.now(), Params: [...args] });
	log(...args);
};

const warn = console.warn.bind(console);
console.warn = (...args) => {
	debug_history.pushToHistory('warn', { Timestamp: Date.now(), Params: [...args] });
	warn(...args);
};

const error = console.error.bind(console);
console.error = (...args) => {
	debug_history.pushToHistory('error', { Timestamp: Date.now(), Params: [...args] });
	error(...args);
};

const debug = console.debug.bind(console);
console.debug = (...args) => {
	debug_history.pushToHistory('debug', { Timestamp: Date.now(), Params: [...args] });
	debug(...args);
};

const info = console.info.bind(console);
console.info = (...args) => {
	debug_history.pushToHistory('info', { Timestamp: Date.now(), Params: [...args] });
	info(...args);
};

export const MouseButtonMap = { LeftButton: 0, ScrollWheel: 1, RightButton: 2 };
export const MouseDownState = [false, false, false, false, false, false, false, false, false];
window.addEventListener('mousedown', (e) => {
	MouseDownState[e.button] = true;
});
window.addEventListener('mouseup', (e) => {
	MouseDownState[e.button] = false;
});

export const MousePosition = { x: 0, y: 0 };
window.addEventListener(
	'mousemove',
	(e) => {
		MousePosition.x = e.pageX;
		MousePosition.y = e.pageY;
	},
	true
);
window.addEventListener('touchmove', (e) => {
	let touch = e.touches.item(0);
	MousePosition.x = touch.pageX;
	MousePosition.y = touch.pageY;
});

// FUTURE: Extend localstorage
