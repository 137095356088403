import React, { createContext, useEffect, useState } from 'react';
import './API/Extensions';

// Import page router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import Firebase functions
import { initializeApp } from 'firebase/app';
import {
	getFirestore,
	//doc,
	//getDoc,
	// updateDoc,
	// serverTimestamp,
	// query,
	// collection,
	// where,
	// orderBy,
} from 'firebase/firestore';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getRemoteConfig, fetchAndActivate, getBoolean, getAll } from 'firebase/remote-config';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// import { useAuthState } from 'react-firebase-hooks/auth';

// import {
// 	//useCollection,
// 	//useDocument,
// 	useDocumentData,
// 	// useCollectionData
// } from 'react-firebase-hooks/firestore';

// import { async } from "@firebase/util";

// Import GSAP
// import { gsap } from './API/gsap';

// Import CSS
import './index.css';
import './styles/AllStyles.scss';

//import Loading from './Components/Loading';

// Import all Layouts
import * as Layouts from './Components/_Core/Layouts/_AllLayouts';

// Import route loaders
// import { loader as loader_tiles } from './Routes/Tiles';

// Import all Routes
import * as Routes from './Components/_Core/Routes/_AllRoutes';

// Define Firebase config values
const firebaseConfig = {
	apiKey: 'AIzaSyD22hjkH5k4MA9QMYIv5hMvtxOpJ_JuNQ0',
	authDomain: 'noahmillerdesign-toolbox.firebaseapp.com',
	databaseURL: 'https://noahmillerdesign-toolbox-default-rtdb.firebaseio.com',
	projectId: 'noahmillerdesign-toolbox',
	storageBucket: 'noahmillerdesign-toolbox.appspot.com',
	messagingSenderId: '918871757632',
	appId: '1:918871757632:web:a8dff42044a307259ce0a5',
	measurementId: 'G-VVWYNXMCN1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings.minimumFetchIntervalMillis = 360;
let remoteConfigDefaults = require('./remote_config_defaults.json');
remoteConfig.defaultConfig = remoteConfigDefaults;
const remoteConfigState = { current: {} };
fetchAndActivate(remoteConfig).finally((result) => {
	remoteConfigState.beta_test = getBoolean(remoteConfig, 'beta_test');
	remoteConfigState.current = getAll(remoteConfig);
});

// Initialize HTTPS Callable Firebase Functions
// const recordLoginTimestamp = httpsCallable(functions, 'userprofile-recordLoginTimestamp');
// const handleRecordLoginTimestamp = (callback) => {
// 	recordLoginTimestamp()
// 		.then((result) => {
// 			return typeof callback === 'function' && callback(result);
// 		})
// 		.catch((error) => {});
// };

// const createMeetUpRequest = httpsCallable(functions, 'requests-createMeetUpRequest');
// const handleCreateMeetUpRequest = (uid, prestige, callback) => {
// 	createMeetUpRequest({ uid, prestige })
// 		.then((result) => {
// 			return typeof callback === 'function' && callback(result);
// 		})
// 		.catch((error) => {});
// };

// const startLookingForConnection = httpsCallable(functions, 'connect-startLookingForConnection');
// const handleStartLookingForConnection = (latitude, longitude, callback) => {
// 	startLookingForConnection({ latitude, longitude })
// 		.then((result) => {
// 			return typeof callback === 'function' && callback(result);
// 		})
// 		.catch((error) => {});
// };

// const stopLookingForConnection = httpsCallable(functions, 'connect-stopLookingForConnection');
// const handleStopLookingForConnection = (callback) => {
// 	stopLookingForConnection()
// 		.then((result) => {
// 			return typeof callback === 'function' && callback(result);
// 		})
// 		.catch((error) => {});
// };

// const engageConnection = httpsCallable(functions, 'connect-engageConnection');
// const handleEngageConnection = (Connection_UUID, callback) => {
// 	engageConnection({ Connection_UUID })
// 		.then((result) => {
// 			return typeof callback === 'function' && callback(result);
// 		})
// 		.catch((error) => {});
// };

const validateKey = httpsCallable(functions, 'veil-validateKey');
const handleValidateKey = (key, path, callback) => {
	validateKey({ key, path })
		.then((result) => {
			return typeof callback === 'function' && callback(result);
		})
		.catch((error) => {});
};

// Initialize App Context
const AppContext = createContext({});

export const Environments = { Production: 'production', Development: 'development', Default: 'default' };

export function useEnvironment() {
	const [environment, environment_set] = useState();

	useEffect(() => {
		if (process.env.NODE_ENV) {
			switch (process.env.NODE_ENV) {
				case Environments.Production:
					environment_set(Environments.Production);
					break;
				case Environments.Development:
					environment_set(Environments.Development);
					break;
				default:
					environment_set(Environments.Default);
			}
		}
	}, []);

	return environment;
}

export function useTitle(title, override = false) {
	useEffect(() => {
		const prevTitle = document.title;
		document.title = override ? title : `NoahMillerDesign | ${title}`;
		return () => {
			document.title = prevTitle;
		};
	}, [title, override]);
}

export function useReadDocumentTitle() {
	const [title, setTitle] = useState(document.title);
	useEffect(() => {
		const titleElem = document.querySelector('title');
		if (!titleElem) return;
		const observer = new MutationObserver(function (mutations) {
			setTitle(mutations[0].target.textContent ?? '');
		});
		observer.observe(titleElem, { subtree: true, characterData: true, childList: true });
		return () => {
			observer.disconnect();
		};
	}, []);

	return title;
}

// Keep the User object up to date
let User = null;
onAuthStateChanged(auth, (user) => {
	if (user) {
		User = user;
	} else {
		User = null;
	}
});

// console.debug('DateTimeFormat', Intl.DateTimeFormat().resolvedOptions());

function App() {
	// const [user, userLoading, userError] = useAuthState(auth);
	// const [userProfile, userProfileLoading] = useDocumentData(doc(db, 'Users', (user && user.uid) || ' '));
	// const [userProfilePrivate, userProfilePrivateLoading] = useDocumentData(doc(db, 'Users', (user && user.uid) || ' ', 'Private', 'Private_Data'));

	const [context, context_set] = useState({});

	const router = createBrowserRouter([
		{
			path: '/veil',
			errorElement: <Routes.ErrorPage />,
			element: (
				<AppContext.Provider value={{ context, context_set }}>
					<Layouts.Veil ValidateKey={handleValidateKey} />
				</AppContext.Provider>
			),
			loader: Layouts.Veil.Loader,
			children: [
				{
					errorElement: <Routes.ErrorPage />,
					children: [
						{
							index: true,
							// element: <Routes.Index />,
							element: <Routes.Veil.VeilIndex />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'cryptex',
							element: <Routes.Veil.CryptexPage />,
							errorElement: <Routes.ErrorPage />,
						},
					],
				},
			],
		},
		{
			path: '/',
			errorElement: <Routes.ErrorPage />,
			element: (
				<AppContext.Provider value={{ context, context_set }}>
					<Layouts.Main />
				</AppContext.Provider>
			),
			// userError ? (
			// 	() => {
			// 		throw userError;
			// 	}
			// ) : userLoading || userProfileLoading ? (
			// 	<Loading />
			// ) : user && userProfile ? (
			// 	userProfilePrivateLoading ? (
			// 		<Loading />
			// 	) : userProfilePrivate ? (
			// 		<Layouts.Main userProfile={userProfile} userProfilePrivate={userProfilePrivate} betaTestEnabled={betaTestEnabled} />
			// 	) : (
			// 		<Routes.ErrorPage />
			// 	)
			// ) : (
			// 	<Layouts.Auth loginCallback={handleRecordLoginTimestamp} />
			// ),
			loader: Layouts.Main.Loader,
			action: () => {},
			children: [
				{
					errorElement: <Routes.ErrorPage />,
					children: [
						{
							index: true,
							// element: <Routes.Index />,
							element: <Routes.NoahMillerDesign />,
							errorElement: <Routes.ErrorPage />,
						},
						{
							path: 'dnd',
							errorElement: <Routes.ErrorPage />,
							children: [
								{
									path: 'stat-calc',
									element: <Routes.DnDStatCalc />,
									errorElement: <Routes.ErrorPage />,
								},
								{
									path: 'mob-attacker',
									element: <Routes.DnDMobAttacker />,
									errorElement: <Routes.ErrorPage />,
								},
							],
						},
						{
							path: 'grid',
							element: <Routes.GridDesigner />,
							errorElement: <Routes.ErrorPage />,
							children: [
								{
									path: 'designer',
									element: <Routes.GridDesigner />,
									errorElement: <Routes.ErrorPage />,
								},
								{
									path: 'template',
									element: <Routes.GridDesigner />,
									errorElement: <Routes.ErrorPage />,
								},
							],
						},
						{
							path: 'toolkit',
							element: <Routes.Toolkit />,
							errorElement: <Routes.ErrorPage />,
						},
						// { // Path with param example
						// 	path: 'contacts/:contactId/edit',
						// 	// element: <Routes.EditContact />,
						// 	loader: contactLoader,
						// 	action: editAction,
						// },
						// { // Action only example
						// 	path: 'contacts/:contactId/destroy',
						// 	errorElement: <div>Oops! There was an error.</div>,
						// 	action: deleteAction,
						// },
						// {
						// 	path: 'tiles',
						// 	element: (
						// 		<AppContext.Provider value={{ screenStates, setScreenStates }}>
						// 			<Routes.Tiles userProfile={userProfile} taskLists={taskLists} />
						// 		</AppContext.Provider>
						// 	),
						// 	loader: loader_tiles,
						// 	errorElement: <Routes.ErrorPage />,
						// },
					],
				},
			],
		},
	]);

	return <RouterProvider router={router} />;
}

export { auth as useAuth, analytics as useAnalytics, db as useDb, User as useUser, remoteConfigState as useRemoteConfigState, AppContext };
export default App;
