import Tick_01 from './Cryptex_SFX_Tick_01.mp3';
import Tick_02 from './Cryptex_SFX_Tick_02.mp3';
import Tick_03 from './Cryptex_SFX_Tick_03.mp3';
import Tick_04 from './Cryptex_SFX_Tick_04.mp3';
import Tick_05 from './Cryptex_SFX_Tick_05.mp3';
import Tick_06 from './Cryptex_SFX_Tick_06.mp3';
import Tick_07 from './Cryptex_SFX_Tick_07.mp3';
import Tick_08 from './Cryptex_SFX_Tick_08.mp3';
import Tick_09 from './Cryptex_SFX_Tick_09.mp3';
import Tick_10 from './Cryptex_SFX_Tick_10.mp3';
import Tick_11 from './Cryptex_SFX_Tick_11.mp3';
import Tick_12 from './Cryptex_SFX_Tick_12.mp3';
import Tick_13 from './Cryptex_SFX_Tick_13.mp3';
import Tick_14 from './Cryptex_SFX_Tick_14.mp3';

const Cryptex_SFX = [Tick_01, Tick_02, Tick_03, Tick_04, Tick_05, Tick_06, Tick_07, Tick_08, Tick_09, Tick_10, Tick_11, Tick_12, Tick_13, Tick_14];
export default Cryptex_SFX;
