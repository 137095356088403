import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from '../../../../App';
import '../../../../styles/RouteStyles/Cryptex.css';
import Cryptex from '../../../Fragments/Cryptex';
import MaterialIcon from '../../../Fragments/MaterialIcon';

function CryptexPage() {
	useEffect(() => {
		logEvent(useAnalytics, 'page_view', { page_name: 'Cryptex' });
	}, []);

	const [audioOn, audioOn_set] = useState(true);

	return (
		<>
			<Container fluid className='w-100 h-100 p-0'>
				<div
					className='AudioOnOff transition rounded-circle border border-2 border-light bg-glass bg-glass-dark transform cursor-pointer'
					style={{
						zIndex: 10000,
						'--blur': '10rem',
						'--top': '1rem',
						'--right': '1rem',
						width: '3rem',
						height: '3rem',
					}}
					onClick={() => audioOn_set(!audioOn)}
				>
					<MaterialIcon className={'transform center-both text-light'} Icon={audioOn ? 'volume_up' : 'volume_off'} IconType={'rounded'} style={{ fontSize: '1.75rem' }} />
				</div>

				<Row className='h-100 align-content-center justify-content-center overflow-hidden'>
					<Col xs='auto'>
						<Cryptex
							Solution='puzzles'
							// DefaultValue={['welcome', 'to the', '  show ', '', ' good  ', '  luck ', '']}
							DefaultValue={['welcome']}
							AudioOn={audioOn}
							GetDialHighlightColor={({ index, solution, currentSelections, solved, focusedDial, loading }) => {
								const highlightColors = {
									default: 'var(--nmd-tk-border-rgb)',
									green: 'var(--nmd-tk-success-rgb)',
									blue: 'var(--nmd-tk-info-rgb)',
									yellow: 'var(--nmd-tk-warning-rgb)',
									red: 'var(--nmd-tk-danger-rgb)',
									white: 'var(--nmd-tk-white-rgb)',
									black: 'var(--nmd-tk-black-rgb)',
								};

								if (loading) return highlightColors.default;

								let currentColor = highlightColors.default;

								const valueOfThis = currentSelections[index];
								const solutionToThis = solution[index];
								const thisValueIsCorrect = valueOfThis === solutionToThis;
								const valueIsTrue = (val) => val === true;
								const valueIsFalse = (val) => val === false;
								const valueIsInSolution = (val) => [...solution].indexOf(val) > -1;

								const getAdjacent = (index) => [index - 1 < 0 ? solution.length - 1 : index - 1, index + 1 > solution.length - 1 ? 0 : index + 1];
								const adjacentStates = getAdjacent(index).map((val) => currentSelections[val] === solution[val]);
								const adjacentValuesAreCorrectForThis = getAdjacent(index).map((val) => currentSelections[val] === solutionToThis);

								// if one adjacent is the correct value for this
								if (adjacentValuesAreCorrectForThis.some(valueIsTrue)) {
									currentColor = highlightColors.yellow;
								}

								// if one adjacent is the correct value for this
								if (valueIsInSolution(valueOfThis)) {
									currentColor = highlightColors.yellow;
								}

								// if one adjacent is correct and one is incorrect
								if (adjacentStates.some(valueIsTrue) && adjacentStates.some(valueIsFalse)) {
									currentColor = highlightColors.blue;
								}

								// if both adjacent are correct and this dial is incorrect
								if (adjacentStates.every(valueIsTrue) && !thisValueIsCorrect) {
									currentColor = highlightColors.blue;
								}

								if (solved) {
									currentColor = highlightColors.green;
								}

								return currentColor;
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default CryptexPage;
