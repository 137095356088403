import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ComponentStyles/Cryptex.css';
import { clamp, getRandomNumerInRange, Letters, playAudio, toggleBodyClass, triggerVibration } from '../../API/Utilities';
import { Col, Row } from 'react-bootstrap';
import MaterialIcon from './MaterialIcon';
import { useContentBounds } from '../_Core/Layouts/Veil';
import { MousePosition } from '../../API/Extensions';
import Cryptex_SFX from '../../sfx/Cryptex_SFX';

const SFX = [...Cryptex_SFX];

Cryptex.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	Solution: PropTypes.string.isRequired,
	DefaultValue: PropTypes.arrayOf(PropTypes.string),
	GetDialHighlightColor: PropTypes.func,
	DialOptions: PropTypes.arrayOf(PropTypes.string),
	Size: PropTypes.number,
	ShowShuffle: PropTypes.bool,
	AudioOn: PropTypes.bool,
	OverflowOptions: PropTypes.number,
};

//Page key: 6ee72f20-1dc5-4723-bd18-c35326c10021

function Cryptex({ className = '', style, Solution, DefaultValue, GetDialHighlightColor, DialOptions = [...Letters, ' '], Size = 10, AudioOn, ShowShuffle = false, OverflowOptions = 2 }) {
	const { contentBounds } = useContentBounds();

	const [audioOn, audioOn_set] = useState(false);
	useEffect(() => audioOn_set(AudioOn), [AudioOn]);

	const [optionSize, optionSize_set] = useState();
	const [overflowOptions, overflowOptions_set] = useState();
	useEffect(() => {
		if (contentBounds) {
			const minSize = 1;
			const targetSize = Size;
			const maxSize = (contentBounds.width / 16 - 2) / [...Solution].length - 2;
			const clampedSize = clamp(minSize, targetSize, maxSize);
			optionSize_set(clampedSize);

			const minOverflow = 1;
			const targetOverflow = OverflowOptions;
			const maxOverflow = Math.floor(((contentBounds.height / 16 - 2) / clampedSize - 1.25) / 2);
			const clampedOverflow = clamp(minOverflow, targetOverflow, maxOverflow);
			overflowOptions_set(clampedOverflow);

			sizing_set(false);
		}
	}, [contentBounds, Size, Solution, OverflowOptions]);

	const [sizing, sizing_set] = useState(true);
	const [initialized, initialized_set] = useState(false);
	const [loading, loading_set] = useState(true);

	const cryptexRef = useRef();

	const [transitionMs, transitionMs_set] = useState(0);

	const [solved, solved_set] = useState(false);

	const getRandomSelections = useCallback(() => [...Solution].map(() => DialOptions.getRandomItem()), [DialOptions, Solution]);
	const [currentSelections, currentSelections_set] = useState(getRandomSelections());

	useEffect(() => {
		if (!initialized && !sizing) {
			const delayTime = 3500;
			initialized_set(true);
			transitionMs_set(delayTime);

			if (DefaultValue && DefaultValue.length > 0) {
				const displayValue = (valueIndex) => {
					if (valueIndex < DefaultValue.length) {
						const currentValue = DefaultValue[valueIndex];
						currentSelections_set(currentValue === '' ? getRandomSelections() : [...currentValue]);

						setTimeout(function () {
							displayValue(valueIndex + 1);
						}, delayTime + 500);
					} else {
						transitionMs_set(550);
						loading_set(false);
					}
				};

				displayValue(0);
			} else {
				currentSelections_set(getRandomSelections());

				setTimeout(() => {
					transitionMs_set(550);
					loading_set(false);
				}, delayTime);
			}
		}
	}, [initialized, sizing, transitionMs, Solution, DefaultValue, DialOptions, getRandomSelections]);

	const [focusedDial, focusedDial_set] = useState(undefined);
	const [hardFocus, hardFocus_set] = useState(false);

	const softFocusDial = (dialIndex) => {
		if (loading || solved) {
			clearFocusDial();
			return;
		}

		!hardFocus && focusedDial_set(dialIndex);
	};

	const hardFocusDial = useCallback(
		(dialIndex) => {
			if (loading || solved) {
				clearFocusDial();
				return;
			}

			focusedDial_set(dialIndex);
			hardFocus_set(true);
		},
		[loading, solved]
	);

	const clearFocusDial = () => {
		focusedDial_set(undefined);
		hardFocus_set(false);
	};

	const shiftFocusDial = useCallback(
		(delta) => {
			if (loading || solved) return;

			const solutionArr = [...Solution];

			if (focusedDial === undefined) {
				hardFocusDial(delta < 0 ? solutionArr.length - 1 : 0);
			} else {
				const targetDial = focusedDial + delta;
				const newIndex = targetDial < 0 ? solutionArr.length - Math.abs(targetDial) : targetDial > solutionArr.length - 1 ? Math.abs(targetDial - solutionArr.length) : targetDial;

				hardFocusDial(newIndex);
			}
		},
		[Solution, focusedDial, hardFocusDial, loading, solved]
	);

	const shiftDial = useCallback(
		(delta) => {
			if (loading || solved || focusedDial === undefined) return;

			let newSelections = [...currentSelections];
			const currentIndex = DialOptions.indexOf(currentSelections[focusedDial]);
			const targetIndex = currentIndex + delta;
			const newIndex = targetIndex < 0 ? DialOptions.length - Math.abs(targetIndex) : targetIndex > DialOptions.length - 1 ? Math.abs(targetIndex - DialOptions.length) : targetIndex;

			newSelections[focusedDial] = DialOptions[newIndex];
			currentSelections_set([...newSelections]);

			triggerVibration([25]);
			audioOn && playAudio(SFX.getRandomItem(), getRandomNumerInRange(0.01, 0.15));
		},
		[DialOptions, currentSelections, focusedDial, loading, solved, audioOn]
	);

	const throttleInputTimeout = useRef();
	useEffect(() => {
		const typeDialValue = (e) => {
			if (loading || solved) return;

			if (throttleInputTimeout.current) return;

			clearTimeout(throttleInputTimeout.current);
			throttleInputTimeout.current = setTimeout(() => {
				throttleInputTimeout.current = false;
			}, 150);

			switch (e.key) {
				case 'Escape':
					clearFocusDial();
					break;

				case 'Backspace':
				case 'ArrowLeft':
					shiftFocusDial(-1);
					break;
				case 'Enter':
				case 'Delete':
				case 'ArrowRight':
					shiftFocusDial(1);
					break;

				case 'ArrowUp':
					shiftDial(-1);
					break;
				case 'ArrowDown':
					shiftDial(1);
					break;

				default:
					const typeDial = focusedDial ?? 0;

					let newSelections = [...currentSelections];
					const newIndex = DialOptions.indexOf(e.key);

					if (newIndex === -1) return;

					newSelections[typeDial] = DialOptions[newIndex];
					currentSelections_set([...newSelections]);

					triggerVibration([25]);
					audioOn && playAudio(SFX.getRandomItem(), getRandomNumerInRange(0.01, 0.15));

					if (focusedDial === undefined) {
						hardFocusDial(1);
					} else {
						shiftFocusDial(1);
					}
					break;
			}
		};
		document.body.addEventListener('keydown', typeDialValue, { passive: false });
		return () => document.body.removeEventListener('keydown', typeDialValue);
	}, [loading, solved, focusedDial, shiftFocusDial, shiftDial, hardFocusDial, currentSelections, DialOptions, audioOn]);

	const [grabDial, grabDial_set] = useState();
	const [grabPosition, grabPosition_set] = useState();
	const handleGrabStart = (e, dialIndex, touch = false) => {
		toggleBodyClass('overscroll-behavior-y-contain', true);

		if (touch) {
			let touchPoint = e.touches.item(0);
			MousePosition.x = touchPoint.pageX;
			MousePosition.y = touchPoint.pageY;
		}

		grabPosition_set({ ...MousePosition });
		hardFocusDial(dialIndex);
		grabDial_set(dialIndex);
	};
	const handleGrabEnd = useCallback(() => {
		toggleBodyClass('overscroll-behavior-y-contain', false);

		grabDial_set(undefined);
		hardFocusDial(undefined);
		grabPosition_set(undefined);
	}, [hardFocusDial]);

	const grabMove = useCallback(() => {
		if (grabDial !== undefined && grabPosition !== undefined) {
			const delta = grabPosition.y - MousePosition.y;
			const shifts = Math.abs(delta) / (optionSize * 0.9 * 16);

			if (shifts > 0.75) {
				shiftDial(delta > 0 ? 1 : -1);
				grabPosition_set({ ...MousePosition });
			}
		}
	}, [grabDial, grabPosition, optionSize, shiftDial]);

	useEffect(() => {
		document.body.addEventListener('mousemove', grabMove, { passive: false });
		return () => document.body.removeEventListener('mousemove', grabMove);
	}, [grabMove]);

	useEffect(() => {
		const grabEnd = () => handleGrabEnd();
		document.body.addEventListener('mouseup', grabEnd, { passive: false });
		return () => document.body.removeEventListener('mouseup', grabEnd);
	}, [handleGrabEnd]);

	useEffect(() => {
		document.body.addEventListener('touchmove', grabMove, { passive: false });
		return () => document.body.removeEventListener('touchmove', grabMove);
	}, [grabMove]);

	useEffect(() => {
		const grabEnd = () => handleGrabEnd();
		document.body.addEventListener('touchend', grabEnd, { passive: false });
		return () => document.body.removeEventListener('touchend', grabEnd);
	}, [handleGrabEnd]);

	const wheelTimeout = useRef();
	const lockScroll = () => {
		clearTimeout(wheelTimeout.current);
		wheelTimeout.current = setTimeout(() => {
			wheelTimeout.current = false;
		}, 300);
	};

	useEffect(() => {
		if (cryptexRef.current) {
			cryptexRef.current.addClickOutListener({
				wrapper: document.body,
				clickOutCallback: () => {
					clearFocusDial();
				},
			});
		}
	}, [cryptexRef]);

	useEffect(() => {
		const cancelWheel = (e) => {
			lockScroll();
			wheelTimeout.current && e.preventDefault();
			shiftDial(e.deltaY > 0 ? 1 : -1);
		};
		document.body.addEventListener('wheel', cancelWheel, { passive: false });
		return () => document.body.removeEventListener('wheel', cancelWheel);
	}, [shiftDial]);

	const solvedTimeout = useRef();
	useEffect(() => {
		clearTimeout(solvedTimeout.current);

		const isSolved = [...Solution].map((val, index) => val === currentSelections[index]).every((v) => v === true);

		if (isSolved) {
			solvedTimeout.current = setTimeout(() => {
				solved_set(true);
				clearFocusDial();
			}, 500);
		} else {
			solved_set(false);
		}
	}, [Solution, currentSelections, solved_set]);

	const dialRefs = useRef([]);

	return (
		<div
			ref={cryptexRef}
			className={`Cryptex fade-in user-select-none ${className}`}
			style={{
				'--fade-in-duration': '3.5s',
				'--size': `${optionSize}rem`,
				'--overflow-options': overflowOptions,
				'--visible-options': `calc(1 + var(--overflow-options) * 2)`,
				...style,
			}}
		>
			<Row className='justify-content-center'>
				{ShowShuffle && (
					<Col xs={'auto'}>
						<Row className='h-100 align-content-center'>
							<Col xs={'auto'}>
								<div style={{ cursor: 'pointer' }} onClick={() => currentSelections_set(getRandomSelections())}>
									<MaterialIcon Icon={'cached'} style={{ fontSize: 'var(--size)', lineHeight: 'var(--size)' }} />
								</div>
							</Col>
						</Row>
					</Col>
				)}

				<span className={`tab_looper transform w-0 h-0 p-0 m-0`} tabIndex={0} onFocus={() => dialRefs.current[focusedDial === undefined ? 0 : dialRefs.current.length - 1].focus()} />

				{[...Solution].map((val, dialIndex) => (
					<Col
						key={dialIndex}
						ref={(el) => (dialRefs.current[dialIndex] = el)}
						xs={'auto'}
						className={`Cryptex_Dial_Col p-0 m-3 position-relative ${focusedDial === dialIndex ? 'focusedDial' : ''}`}
						tabIndex={0}
						onFocus={() => hardFocusDial(dialIndex)}
					>
						<div
							className='Cryptex_Dial overflow-hidden bg-glass bg-glass-dark border border-2'
							style={{
								width: 'var(--size)',
								height: `calc(var(--size) * var(--visible-options))`,
								cursor: solved ? 'default' : grabDial !== undefined ? 'grab' : 'ns-resize',
							}}
							onMouseMove={() => softFocusDial(dialIndex)}
							onMouseLeave={() => softFocusDial(undefined)}
							onClick={() => hardFocusDial(dialIndex)}
							onMouseDown={(e) => handleGrabStart(e, dialIndex)}
							onTouchStart={(e) => handleGrabStart(e, dialIndex, true)}
						>
							<div
								className='Cryptex_Dial_Options transform center-both position-relative'
								style={{
									width: 'var(--size)',
									height: `var(--size)`,
									'--transitionMs': transitionMs,
								}}
							>
								{[...DialOptions].map((option, optionIndex) => {
									const baseOffset = optionIndex - DialOptions.indexOf(currentSelections[dialIndex]);
									const indexOffset =
										baseOffset > DialOptions.length / 2
											? baseOffset - DialOptions.length
											: baseOffset < (DialOptions.length / 2) * -1
											? baseOffset + DialOptions.length
											: baseOffset;

									return (
										<div
											key={optionIndex}
											className='Cryptex_Dial_Options_Text transform'
											style={{
												'--index-offset': indexOffset,
												'--translateY': `calc(var(--size) * (var(--index-offset)))`,
												opacity: Math.abs(indexOffset) > overflowOptions * 2 ? 0 : 1,
												zIndex: Math.abs(indexOffset),
												width: 'var(--size)',
												height: 'var(--size)',
											}}
										>
											<div
												className={`text-uppercase text-center font-brand text-outline`}
												style={{
													width: 'var(--size)',
													height: 'var(--size)',
													lineHeight: 'var(--size)',
													fontSize: `calc(var(--size) * 0.9)`,
													textShadow: 'calc(var(--size) * -0.05) calc(var(--size) * 0.05) calc(var(--size) * 0.15) var(--nmd-tk-black)',
													'--outline-color': 'var(--nmd-tk-light)',
													'--outline-width': 'round(down, calc(var(--size) * 0.015), 1px)',
												}}
											>
												{option === ' ' ? '_' : option}
											</div>
										</div>
									);
								})}
							</div>
						</div>

						<div
							className={`Cryptex_Dial_Selection pe-none transform center-both shadow-pop shadow-pop-border`}
							style={{
								'--highlight-color': GetDialHighlightColor
									? GetDialHighlightColor({ index: dialIndex, solution: Solution, currentSelections, solved, focusedDial, loading })
									: solved
									? 'var(--nmd-tk-success-rgb)'
									: 'var(--nmd-tk-border-rgb)',
								width: 'var(--size)',
								height: 'var(--size)',
								outline: 'calc(var(--size) * 0.1 * (3/16)) solid rgba(var(--highlight-color), 1)',
								'--shadow-color': solved ? 'var(--nmd-tk-success)' : '',
								'--shadow-pop-width': 'calc(var(--size) * 0.1 * (2/16))',
								'--shadow-pop-opacity': '1',
								borderRadius: 'calc(var(--size) * 0.1)',
							}}
						></div>
					</Col>
				))}

				<span className={`tab_looper transform w-0 h-0 p-0 m-0`} tabIndex={0} onFocus={() => dialRefs.current[0].focus()} />
			</Row>
		</div>
	);
}

export default Cryptex;
